var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Add Post","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"h3"},[_vm._v("Main Data")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Upload Cover Here'),expression:"'Upload Cover Here'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Cover","label-for":"cover"}},[_c('validation-provider',{attrs:{"name":"Cover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"id":"ProfilePictureBase64","accept":".jpg, .png"},on:{"change":_vm.uploadImage},model:{value:(_vm.ProfilePictureBase64),callback:function ($$v) {_vm.ProfilePictureBase64=$$v},expression:"ProfilePictureBase64"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Active","label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"active","state":_vm.activeSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.activeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.activeSelect),callback:function ($$v) {_vm.activeSelect=$$v},expression:"activeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category","label-for":"Category","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Category","state":_vm.categorySelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.categorySelect),callback:function ($$v) {_vm.categorySelect=$$v},expression:"categorySelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Commentable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Commentable","label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Commentable","state":_vm.commentableSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commentableOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.commentableSelect),callback:function ($$v) {_vm.commentableSelect=$$v},expression:"commentableSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Brand","label-for":"Brand"}},[_c('v-select',{attrs:{"id":"brand","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.brandOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.brandSelect),callback:function ($$v) {_vm.brandSelect=$$v},expression:"brandSelect"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Item","label-for":"Item"}},[_c('v-select',{attrs:{"id":"item","disabled":_vm.itemSe,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions.map(function (item) { return ({ value : item.id , text:item.name }); }),"label":"text"},model:{value:(_vm.itemSelect),callback:function ($$v) {_vm.itemSelect=$$v},expression:"itemSelect"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"multiple":"","id":"tag","mu":"","state":_vm.tagSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.tagOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.tagSelect),callback:function ($$v) {_vm.tagSelect=$$v},expression:"tagSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"h3"},[_vm._v("Post Body")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Body","label-for":"Body"}},[_c('validation-provider',{attrs:{"name":"Body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":"Body","state":errors.length > 0 ? false : null,"rows":"20"},model:{value:(_vm.data.body),callback:function ($$v) {_vm.$set(_vm.data, "body", $$v)},expression:"data.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,indexVal){return _c('li',{key:indexVal},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }