<template>
  <div>
    <b-card title="Add Post" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <p class="h3">Main Data</p>
            </b-col>
            <!-- cover -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Upload Cover Here'"
                label="Cover"
                label-for="cover"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cover"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase64"
                    :state="errors.length > 0 ? false : null"
                    @change="uploadImage"
                    id="ProfilePictureBase64"
                    accept=".jpg, .png"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- category -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group
                  label="Category"
                  label-for="Category"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Category"
                    v-model="categorySelect"
                    :state="categorySelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categoryOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- commentable -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Commentable"
                rules="required"
              >
                <b-form-group
                  label="Commentable"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Commentable"
                    v-model="commentableSelect"
                    :state="commentableSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="commentableOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Brand -->
            <b-col md="6">
              <b-form-group label="Brand" label-for="Brand">
                <v-select
                  id="brand"
                  v-model="brandSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="brandOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
              </b-form-group>
            </b-col>
            <!-- item -->
            <b-col md="6">
              <b-form-group label="Item" label-for="Item">
                <v-select
                  id="item"
                  v-model="itemSelect"
                  :disabled="itemSe"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              
                  :options="itemOptions.map(item => ({ value : item.id , text:item.name }))"
                   
                  label="text"
                />
              </b-form-group>
            </b-col>
            <!-- Tags -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="tag"
                rules="required"
              >
                <b-form-group
                  label="Tag"
                  label-for="tag"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    multiple
                    id="tag"
                    mu
                    v-model="tagSelect"
                    :state="tagSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tagOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <p class="h3">Post Body</p>
            </b-col>
            <!-- title -->
            <b-col md="6">
              <b-form-group label="Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- Description -->
              <b-col md="12">
                <b-form-group label="Body" label-for="Body">
                  <validation-provider
                    #default="{ errors }"
                    name="Body"
                    rules="required"
                  >
                    <vue-editor
                      id="Body"
                      v-model="data.body"
                      :state="errors.length > 0 ? false : null"
                      rows="20"
                    ></vue-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'

// import axios from '@/libs/axios'
export default {
  components: {
    VueEditor,
  },

  mixins: [heightTransition],
  watch: {
    brandSelect: function () {
      this.itemOptions = [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ]
      if (this.brandSelect != null) {
        this.getItems()
      } else {
        this.itemSe = true
      }
    },
  },
  data() {
    return {
      ProfilePictureBase64: [],
      itemSe: true,
      activeSelect: null,
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      commentableSelect: null,
      commentableOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      categorySelect: null,
      categoryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      itemSelect: null,
      itemOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      brandSelect: null,
      brandOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      tagSelect: null,
      tagOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      data: {
        cover: [],
        active: '',
        title: '',
        body: '',
        commentable: '',
        item_id: '',
        post_category_id: '',
        tags: [],
      },
      errors_back: '',
      showDismissibleAlert: false,
      required,
    }
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.active = this.activeSelect.value
          this.data.commentable = this.commentableSelect.value
          this.data.post_category_id = this.categorySelect.value
          if (this.itemSelect != null) {
            this.data.item_id = this.itemSelect.value
          } else {
            delete this.data.item_id
          }
          this.data.tags = []
          for (let tag in this.tagSelect) {
            this.data.tags.push(this.tagSelect[tag].value)
          }
          if (this.data.cover.includes(',')) {
            this.data.cover = this.data.cover
              .split(',')
              .map((item) => item.trim())
            this.data.cover = this.data.cover[1]
          }
          for (let trn in this.data.translations) {
            this.data.translations[trn].locale =
              this.tranLocaleSelect[trn].value
          }
          axios
            .post('posts', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({
                path: '/posts/index',
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    getItems() {
      axios
        .get('products/brands/' + this.brandSelect.value + '/items/get')
        .then((result) => {
          this.itemSe = false
          this.itemOptions = result.data.data
     
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.data.cover = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // basic
  },
  created() {
    axios
      .get('post-categories')
      .then((result) => {
        const data = result.data.data
        if (data.length > 0) {
          for (let ind in data) {
            if (data[ind].post_category_translations != null) {
              this.categoryOptions.push({
                text: data[ind].post_category_translations.name,
                value: data[ind].id.toString(),
              })
            } else {
              this.categoryOptions.push({
                text: 'undefinded',
                value: data[ind].id.toString(),
              })
            }
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    axios
      .get('products/create')
      .then((result) => {
        const data = result.data.data
        if (data.brands.length > 0) {
          for (let ind in data.brands) {
            if (data.brands[ind].translation != null) {
              this.brandOptions.push({
                text: data.brands[ind].translation.name,
                value: data.brands[ind].id.toString(),
              })
            }
          }
        }
        if (data.tags.length > 0) {
          for (let ta in data.tags) {
            this.tagOptions.push({
              value: data.tags[ta].id.toString(),
              text: data.tags[ta].name,
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>
 
<style lang="scss">

</style>


<style lang="scss" scoped>
</style>